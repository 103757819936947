<template>
  <main>
    <div class="container">
      <div
        class="col-sm-8 mx-auto py-5 mt-5 justify-content-center"
        style="min-height: 60vh"
      >
        <div class="message-box text- p-4 rounded-lg shadow-mdn py-5">
          <div class="row">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-">Votre candidature est bien enregistrée!</h4>
                <a
                  href="#"
                  @click.prevent="gotoToCandidatureList"
                  class="mt-4 btn btn-primary"
                  >Voir la liste de mes candidatures</a
                >

                <!-- <h6 class="text-muted mb-4">Veuillez prendre contact avec votre conseiller en emploi</h6> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    gotoToCandidatureList() {
      this.$router.push({
        name: "espace.common.candidature.list",
        params: { userRole: "de" },
      });
    },
  },
};
</script>

<style>
.message-box {
  background: #ffc10745 !important;
}
</style>
